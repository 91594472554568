//Imports the Router Link

//Imports Form Assets
import React, { useRef } from 'react';
import emailjs from '@emailjs/browser';
//Imports Toastify Assets
import { toast, ToastContainer } from 'react-toastify';

import 'react-toastify/dist/ReactToastify.css';

const Home = () => {

const notify = () => {
    toast.success('Message sent Successfuly! We will be in touch shortly.', {
        position: "top-center",
        autoClose: 7000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "dark",
        });
}

const form = useRef();

const sendEmail = (e) => {
    e.preventDefault();

    emailjs.sendForm('service_fsfd4w9', 'template_lwz1wiq', form.current, 'EJ5A6FqAvo4-sD8oX')
    .then((result) => {
        console.log(result.text);
        console.log("Message has been sent");   
      
         
    } 
    , (error) => {
        console.log(error.text);
    });    
    e.target.reset();
}

//Social links
const twitterLink = () => {
window.location.replace('https://www.twitter.com');
};

const linkedInLink = () => {
window.location.replace('https://uk.linkedin.com/in/aarondulai?original_referer=https%3A%2F%2Fwww.google.com%2F');
};

const instagramLink = () => {
window.location.replace('https://www.instagram.com/zerostudiosuk/?hl=en');
};

    return ( 
        <>
            <div className="homeImage">  
             
                <div className="-desktop-homeImage">
                    <img src='/images/homeImage.png' class='-desktop-homeImage' alt="DesktopHomeImage"/>
                        <img src='/images/homeSloganText.png' class='homeSloganText' alt="Slogan"/>     
                </div>
                <div className="-tablet-homeImage">
                    <img src='/images/-tablet-homeImg.png' class='-tablet-homeImage' alt="TabletHomeImage"/>
                </div>
                <div className="-mobile-homeImage">
                    <img src='/images/mobileHomeImg.png' class='-mobile-homeImage' alt="MobileHomeImage"/>
                </div>
            </div>      
            <div className="homeTextSection" id="about">   
                <div className="homeTextSectionLight">
                    <h1>WHO WE ARE</h1>
                        <p2>
                        We are highly skilled professional developers who have our client 
                        needs at our hearts, we design, develop and deliver basic to 
                        complex websites for any client needs. Alongside our amazing 
                        websites, we can also enhance your business progression by creating 
                        still advertisement assets such as images, slogans, banners and 
                        posters as well as logos.  
                        </p2>
                </div>
                <div className="homeTextSectionDark">
                    <h1>OUR GOAL</h1>
                        <p2>
                        At Zero Studios, we believe it is important to always maintain 
                        high quality and consistency throughout any project, whether it’s 
                        creating an entire website from scratch or adding content to an 
                        existing one. We go through every possible solution with our clients, 
                        support their needs and spark their ideas as well as keep them in 
                        the limelight with all progress so nothing gets missed or left 
                        behind. We plan to increase our projects portfolio so if you’re 
                        reading this, let’s work together!  
                        </p2>
                </div>
                <div className="servicesSection">
                        <h4>OUR SERVICES</h4>
                        <h2>---</h2>
                    <div className="servicesTextSection1">
                        <h3>WEB DEVELOPMENT</h3>
                            <div className="textSpace"/>
                        <p3>
                        We design, develop and deliver bespoke sites as well as fix any 
                        potential issues or add content to your current site(s). 
                        </p3>
                        <div className="servicesIcons">
                            <img src='/images/webDevelopmentIcon.png' class='servicesIcons' alt="WebDevelopmentIcon"/>
                        </div>
                    </div>
                        <div className="-mobile-textSpace"/>
                    <div className="servicesTextSection2">
                        <h3>LOGO CREATION</h3>
                            <div className="textSpace"/>
                        <p3>
                        The most important asset to your business, your logo, 
                        we make sure the logo and slogan represents you and stands out.
                        </p3>
                        <div className="servicesIcons">
                            <img src='/images/logoCreationIcon.png' class='servicesIcons' alt="logoCreationIcon"/>
                        </div>
                    </div>
                        <div className="textSpace"/>
                    <div className="servicesTextSection3">
                        <h3>POSTER DESIGNS</h3>
                            <div className="textSpace"/>
                        <p3>
                        We add all the relevant information you need, clear and 
                        precise to catch the eye of the audience without causing confusion.
                        </p3>
                        <div className="servicesIcons">
                            <img src='/images/posterCreationIcon.png' class='servicesIcons' alt="posterCreationIcon"/>
                        </div>
                    </div>
                        <div className="-mobile-textSpace"/>
                    <div className="servicesTextSection4">
                        <h3>BANNER DESIGNS</h3>
                            <div className="textSpace"/>
                        <p3>
                        Our marketing knowledge compresses and transforms 
                        the most relevant and desirable information for a banner of any size.
                        </p3>
                        <div className="servicesIcons">
                            <img src='/images/bannerCreationIcon.png' class='servicesIcons' alt="bannerCreationIcon"/>
                        </div>
                    </div>
                </div>
                <div className="headingSection"  id="projects">
                        <h4>OUR PROJECTS</h4>
                        <h2>---</h2>
                    <div className="projectSection">
                        <div className="textSection">
                                <h3>Compare My Cover</h3>
                                    <p3>
                                    A life insurance company, our client Compare My Cover 
                                    required a website, a logo and a banner for a football 
                                    pitch as well as multiple custom slogans used for 
                                    social media posts. 
                                    </p3>
                            </div>
                        </div>
                        <div className="projectSection">
                            <div className="textSection">                             
                                <h3>Zero Studios</h3>
                                    <p3>
                                    Well, no introduction needed. All logos, slogans, buttons 
                                    and functionality were built in house. This is an example 
                                    of what could be done for you. Follow our social pages to 
                                    see what else we can do.
                                    </p3>
                            </div>
                        </div>
                    </div>
                <div className="textSpace"/>
            </div>
            <div className="requestForm" id="contact">
                    <h4>Have a question?</h4>
                    <div className="formMessage">
                        <p4>
                            Get in touch by filling in the form below and we will 
                            get back to you as soon as possible. alternatively, 
                            reach out to us on our social media links below.
                        </p4>
                    </div>
                <form ref={form} onSubmit={sendEmail} >

                    <input id ="yourName" name="yourName" type="text" placeholder="Your Name" required/>

                            <div className="formTextSpace"/>
                    
                    <input id="yourEmail" name="yourEmail" type="text" placeholder="Your Email" required/>
                    
                            <div className="formTextSpace"/>
                    
                    <input id="yourMobile" name="yourMobile" type="text" placeholder="Your Mobile" required/>
                    
                            <div className="formTextSpace"/>
        
                    <input id="subject" name="subject" type="text" placeholder="Subject" required/>
                            
                            <div className="formTextSpace"/>
                    
                    <textarea id="message" name="message" placeholder="Message" required/>

                            <div className="formTextSpace"/>
                            <div className="mobileTextSpace"/>

                    <button className="sendRequestButton" type="submit" onClick={notify}>Send Message</button>

                    <div className="confirmationMessage">
                        <p3>Message sent successfully, we will be in touch shortly!</p3>
                    </div>
                </form>
                            <div className="formTextSpace"/>
                            <div className="mobileTextSpace"/>
                <div className="socialIcons">
                    <div className="twitterIcon">
                        <button className="twitterIconButton">
                            <img src='/images/twitterIcon.png' class='twitterIconImage' alt="TwitterIconImage" onClick={twitterLink}/>
                        </button>
                    </div>
                    <div className="linkedInIcon">
                        <button className="linkedInIconButton">
                            <img src='/images/linkedInIcon.png' class='linkedInIconImage' alt="LinkedInIconImage" onClick={linkedInLink}/>
                        </button>
                    </div>
                    <div className="instaIcon">
                        <button className="instaIconButton">
                            <img src='/images/instaIcon.png' class='instaIconImage' alt="InstaIconImage" onClick={instagramLink}/>
                        </button>
                    </div>
                </div>
                    <div className="mobileTextSpace"/>
            </div>
            <ToastContainer />
        </>
     );
} 


export default Home;