// Import Hashlink for Navigation
import { HashLink as Link} from 'react-router-hash-link';

const Navigation = () => {

    return (
        <nav class="navbar navbar-expand-lg navbarContainer">
        <div class="container">
            <img src='/images/logo.png'  class="logo" alt="Logo"></img>
            <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation"><span class="navbar-toggler-icon"></span></button>
            <div class="collapse navbar-collapse" id="navbarSupportedContent">
                <ul class="navbar-nav ms-auto mb-2 mb-lg-0">
                    <li class="nav-item">
                        <a class="nav-link" href="/#projects">
                            <Link to="#projects">Projects</Link>
                        </a>
                    </li>
                    <li class="nav-item">
                        <a class="nav-link" href="/#about">
                            <Link to="#about">About</Link>
                        </a>
                    </li>
                    <li class="nav-item">
                        <a class="nav-link" href="/#contact">
                            <Link to="#contact">Contact</Link>
                        </a>
                    </li>
                </ul>
            </div>
        </div>
    </nav>
    )
}

export default Navigation;