import './App.css';
// Bootstrap CSS
import "bootstrap/dist/css/bootstrap.min.css";
// Bootstrap Bundle JS
import "bootstrap/dist/js/bootstrap.bundle.min";
import { BrowserRouter , Routes, Route} from 'react-router-dom';
// Import Components
import Navigation from './components/navigation';
import Footer from './components/footer';
// Import Pages
import Home from './pages/home';

function App() {

  return (
    <BrowserRouter>
      <header>
        <nav>
          <Navigation />
        </nav>
      </header>
      <main>
        <Routes>
          <Route index element ={<Home />} />
          <Route path="home" element={<Home />} />     
        </Routes>
      </main>
    
      <footer>
        <Footer />
      </footer>
    </BrowserRouter>
  );
}
export default App;
